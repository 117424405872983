const Const = {
    widgetContainerClass: 'garderobo-widget-container',
    widgetFeedContainerClass: 'garderobo-widget-feed',
    widgetFeedHeaderClass: 'garderobo-widget-feed-header',
    widgetFeedHeaderWithCounterClass: 'garderobo-widget-feed-header--with-counter',
    widgetFeedItemsCounterClass: 'garderobo-widget-feed-items-counter',
    widgetFeedItemsCounterTextClass: 'garderobo-widget-feed-items-counter-text',
    widgetFeedItemsCounterTextCurrentClass: 'garderobo-widget-feed-items-counter-text-current',
    widgetFeedItemsCounterTextTotalClass: 'garderobo-widget-feed-items-counter-text-total',
    widgetFeedItemsCounterBtnClass: 'garderobo-widget-feed-items-counter-btn',
    widgetFeedItemsCounterBtnPrevClass: 'garderobo-widget-feed-items-counter-btn--prev',
    widgetFeedItemsCounterBtnNextClass: 'garderobo-widget-feed-items-counter-btn--next',
    widgetFeedItemContainerClass: 'garderobo-widget-feed-container',
    widgetFeedItemContainerMobileClass: 'garderobo-widget-feed-container--mobile',
    widgetFeedItemContainerNewClass: 'garderobo-widget-feed-container-new',
    widgetLeftButtonClass: 'garderobo-widget-control-left',
    widgetRightButtonClass: 'garderobo-widget-control-right',
    widgetFeedItems: 'garderobo-widget-feed-items',
    widgetFeedItemsLessThanPerPage: 'garderobo-widget-feed-less-than-per-page',
    widgetItemClass: 'garderobo-widget-feed-item',
    widgetItemMinWidthClass: 'garderobo-widget-feed-item-min-width',
    widgetImageClass: 'garderobo-widget-product-image',
    widgetHeaderClass: 'garderobo-widget-product-header',
    widgetHeaderHasContentClass: 'garderobo-widget-product-header-content',
    widgetFooterClass: 'garderobo-widget-product-footer',
    widgetNameClass: 'garderobo-widget-product-name',
    widgetBrandClass: 'garderobo-widget-brand-name',
    widgetItemControlContainerClass: 'garderobo-widget-product-control-container',
    widgetItemControlLikeClass: 'garderobo-widget-product-control-like',
    widgetItemControlDislikeClass: 'garderobo-widget-product-control-dislike',
    widgetPriceContainerClass: 'garderobo-widget-product-price-container',
    widgetPriceClass: 'garderobo-widget-product-price',
    widgetSizesClass: 'garderobo-widget-sizes',
    widgetBrandAndPriceClass: 'garderobo-widget-brand-and-price',
    widgetSizesAndCartBtnClass: 'garderobo-widget-sizes-and-cart-btn',
    widgetAddToCartClass: 'garderobo-widget-add-to-cart-btn',
    widgetAddToCartNoSizeClass: 'garderobo-widget-add-to-cart-no-size-btn',
    widgetOldPriceClass: 'garderobo-widget-product-old-price',
    widgetAddAllProductWrapClass: 'garderobo-widget-add-all-product-wrap',
    widgetDiscountPercentClass: 'garderobo-widget-product-discount-percent',
    widgetMutedClass: 'garderobo-widget-muted',
    widgetHiddenClass: 'garderobo-widget-hidden',
    widgetDisplayedNoneClass: 'garderobo-widget-displayed-none',
    widgetSaleBadgeClass: 'garderobo-widget-product-sale-badge',
    widgetSizesListClass: 'garderobo-widget-sizes-list',
    widgetSizesListItemClass: 'garderobo-widget-sizes-list-item',
    widgetSessionKey: '_garderoboSessionKey',
    widgetLastItemsKey: '_garderoboLastItems',
    widgetLastItemsIdsKey: '_garderoboLastItemsIds',
    widgetParamsLastUpdateKey: '_garderoboWidgetParamsLastUpdate',
    widgetParamsKey: '_garderoboWidgetParams',
    widgetABTestKey: '_garderoboABTest',
    widgetLangCode: '_garderoboWidgetLangCode',
    widgetABTestKeyNew: '_garderoboAB',
    scrollButtonClass: 'garderobo-scroll-button'
};

export const Texts = {
    LOOK_BUY_BTN: 'LOOK_BUY_BTN',
    LOOK_GOTO_LINK: 'LOOK_GOTO_LINK',
    SELECT_SIZE: 'Select size',
    TO_CART: 'ADD_TO_CARD',
    OPEN_CART: 'OPEN_CART',
    ALL_TO_CART: 'ALL_ADD_TO_CARD',
    ITEMS: 'ITEMS',
    CHANGE: 'CHANGE',
    PLEASE_SELECT_SIZE: 'PLEASE_SELECT_SIZE',
    PLEASE_SELECT_SIZE_ALL: 'PLEASE_SELECT_SIZE_ALL',
    MODEL: 'MODEL',
    HOW_TO_WEAR_IT: 'HOW_TO_WEAR_IT',
    NEXT_LOOK: 'NEXT_LOOK',
    PREV_LOOK: 'PREV_LOOK',
    TOTAL_LOOK: 'TOTAL_LOOK',
    ONE_SIZE: 'ONE_SIZE',
    SIMILAR_ITEMS: 'SIMILAR_ITEMS',

    SUBSCRIBE: 'SUBSCRIBE',
    UNSUBSCRIBE: 'UNSUBSCRIBE',
    MORE_ARTICLES: 'MORE_ARTICLES',

    ADD_TO_FAVORITES: 'ADD_TO_FAVORITES',
    UNFAVORITE: 'UNFAVORITE',
};

export const BlockShowType = {
    COLLAGE_BY_TEMPLATES: 'collage_by_templates',
    HORIZONTAL_WITH_POPUP: 'horizontal_with_popup',
    HORIZONTAL_WITH_POPUP_AND_SIMILARS: 'horizontal_with_popup_and_similars',
};

export const WidgetType = { RECOMMEND: 'RECOMMEND', LOOKS: 'LOOKS' };

export default Const;
